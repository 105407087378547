import React from "react"
import Layout from "../../layouts" // ここで英語のレイアウトを選択します
import { FormattedMessage } from "react-intl"
import { PageProps } from "../../commonTypes"

function IndexPage(props: PageProps) {
  return (
    <Layout lang="en" location={props.location}>
      <h1>Samples of Multilingual Display</h1>
      <p>
        デフォルトでは日本語が指定されます。このページは /translation-example/
        に出力されます。
      </p>
      <p>かならず Layout で囲うようにしてください。</p>
      <p>共通の UI で言語を分けたい場合は、 FormattedMessage を利用します。</p>
      <button>
        <FormattedMessage id="dummyMessage1" />
      </button>
    </Layout>
  )
}

export default IndexPage
